import { useGetTopNewsHarvest, useGetTopNewsProducts } from '@/api/kubik/infoportal'
import { TNewsSection } from './types'

export const useNewsApi = (type: TNewsSection) => {
  const {
    data: inputsNews,
    isError: isInputsNewsError,
    isSuccess: isInputsNewsSuccess,
  } = useGetTopNewsProducts({ query: { staleTime: Infinity, enabled: type === 'inputs' } })

  const {
    data: outputsNews,
    isSuccess: isOutputsNewsSuccess,
    isError: isOutputsNewsError,
  } = useGetTopNewsHarvest({ query: { staleTime: Infinity, enabled: type === 'outputs' } })

  return {
    news: type === 'inputs' ? inputsNews : outputsNews,
    isSuccess: type === 'inputs' ? isInputsNewsSuccess : isOutputsNewsSuccess,
    isError: type === 'inputs' ? isInputsNewsError : isOutputsNewsError,
  }
}
