import React, { memo } from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Skeleton } from '@frontend/pole-ui/lib/components/Skeleton'
import classNames from 'classnames'
import { Image, PaginationDots } from '@/components/ui'
import { MATCH_MEDIA_QUERIES } from '@/constants/common'
import { useSlider } from '@/utils/hooks/keenSlider/useSlider'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { getMinioImagePath } from '@/utils/images'
import { skeletons } from './constants'
import { IPromoSliderBlockProps } from './types'
import './styles.scss'

const PromoSliderBlock = (props: IPromoSliderBlockProps) => {
  const { isLoading, promoBanners, visibleItems, onBannerClickGtmEvent, onViewCardRefCallback, slideClassName } = props

  const { isMobile } = useDeviceType()
  const isSingleBanner = visibleItems === 1

  const { productsSliderRef, control, activeSlide, totalSlides } = useSlider({
    keenSliderOptions: { slides: { perView: 'auto', spacing: 16 }, loop: isSingleBanner, selector: '.promoSlider__slide' },
    slides: promoBanners,
  })

  const { onPaginationDotClick } = control

  const shouldShowPaginationDots = isSingleBanner && promoBanners && promoBanners.length > 1

  return (
    <div className="promoSlider">
      <Skeleton isLoading={isLoading} skeletons={skeletons}>
        <div ref={productsSliderRef} className="keen-slider promoSlider__slider">
          {promoBanners?.map((banner, index) => (
            <div
              key={banner.webImage}
              onClick={() => onBannerClickGtmEvent?.(banner.url || String(index))}
              ref={node => onViewCardRefCallback?.({ index, node, productId: banner.url || String(index) })}
              className={classNames('promoSlider__slide', { 'promoSlider__slide--singleBanner': isSingleBanner }, slideClassName)}
            >
              <picture>
                <source srcSet={getMinioImagePath({ src: banner.mobileImage })} media={MATCH_MEDIA_QUERIES.mobile} />
                <Image src={banner.webImage} className="promoSlider__image" />
              </picture>
              <CommonLink className="promoSlider__link" spanIfEmpty url={banner.url} />
            </div>
          ))}
        </div>

        {shouldShowPaginationDots && (
          <PaginationDots
            className="promoSlider__paginationDots"
            dotsQuantity={totalSlides}
            activeDotNumber={activeSlide + 1}
            onClick={onPaginationDotClick}
            dotSize={6}
            activeDotWidth={16}
            activeDotStyle={isMobile ? EColor.BLACK : EColor.WHITE}
          />
        )}
      </Skeleton>
    </div>
  )
}

export default memo(PromoSliderBlock)
