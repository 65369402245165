import React, { FC } from 'react'
import classNames from 'classnames'
import { SaleRequestForm } from '@/pages/IndexPage/sections/Outputs/components'
import { messages } from './messages'
import { ICultureSaleCardProps } from './types'
import './styles.scss'

const CultureSaleCard: FC<ICultureSaleCardProps> = props => {
  const { culture, className } = props

  return (
    <div className={classNames('cultureSaleCard', className)}>
      <article className={classNames('cultureSaleCard__card')}>
        <div className="cultureSaleCard__wrapper">
          <h3 className="cultureSaleCard__header">{messages.header}</h3>
          <p className="cultureSaleCard__lead">{messages.lead}</p>
        </div>
        <SaleRequestForm culture={culture} />
      </article>
    </div>
  )
}

export default CultureSaleCard
