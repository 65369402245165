import React, { FC, memo } from 'react'
import { useGetSaleTemplatesBestOffers } from '@/api/kubik/monolith'
import { useOnProductCardsViewGtmEvent, useProductCardInteractGtmEvents } from '@/logic/metrika/index/catalog/productCards'
import { CardSliderBlock, IndexPageSection, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import { messages } from './messages'
import './styles.scss'

const BestOffersOutputs: FC = () => {
  const { data: bestOffersOutputs, isLoading, isSuccess, isError } = useGetSaleTemplatesBestOffers()

  const setRefCallback = useOnProductCardsViewGtmEvent({ products: bestOffersOutputs?.data?.templates, blockName: 'mainBlock' })
  const { outputsGtmEvents } = useProductCardInteractGtmEvents({ blockName: 'mainBlock' })

  const shouldHideBlock = (isSuccess && !bestOffersOutputs.data?.templates?.length) || isError

  if (shouldHideBlock) return null

  return (
    <IndexPageSection>
      <SectionBlock>
        <SectionHeading header={messages.header} />
        <CardSliderBlock
          products={bestOffersOutputs?.data?.templates}
          isLoading={isLoading}
          cardOptions={{ outputs: { isWithImage: true } }}
          gtmEvents={{ onViewCardRefCallback: setRefCallback, ...outputsGtmEvents }}
        />
      </SectionBlock>
    </IndexPageSection>
  )
}

export default memo(BestOffersOutputs)
