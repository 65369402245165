import React, { ElementRef, memo, useRef } from 'react'
import { ITextInputProps } from '@frontend/pole-ui/lib/components/TextInput'
import SearchInputWithSuggestions from '@/components/composed/search/SearchInputWithSuggestions'
import { useIndexSearchGtmEvent } from '@/logic/metrika/index/search/gtmEvents'
import { useCatalogSearch } from '@/pages/IndexPage/hooks/useCatalogSearch'
import { TMarketSearchSuggestion } from '@/services/MarketSearchService/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { SearchInputWrapper } from './components/'
import { MIN_LENGTH } from './constants'
import { ISearchBarProps } from './types'
import './styles.scss'

const InputsSearchBar = (props: ISearchBarProps) => {
  const { placeholder } = props
  const { onSearch } = useCatalogSearch()
  const { isMobile } = useDeviceType()

  const searchString = useRef<string>('')
  const inputRef = useRef<ElementRef<typeof SearchInputWithSuggestions>>(null)

  const { onSearchButtonClickGtmEvent, onSearchInputGtmEvent, onSearchResultSelectGtmEvent } = useIndexSearchGtmEvent()

  const onSearchInput = (newValue: string) => {
    const preparedNewValue = newValue?.trim() || null

    if (preparedNewValue) onSearchButtonClickGtmEvent(preparedNewValue)

    if ((preparedNewValue?.length ?? 0) < MIN_LENGTH || !preparedNewValue) return

    inputRef.current?.hideModal()

    onSearch(preparedNewValue)
  }

  const onSuggestionChange = (suggestion: Maybe<TMarketSearchSuggestion>) => {
    if (!suggestion) return
    onSearchResultSelectGtmEvent(searchString.current, suggestion.id)
  }

  const onChange = (inputValue: string) => {
    searchString.current = inputValue
    onSearchInputGtmEvent()
  }

  const mobileModifiers: ITextInputProps['modifiers'] = ['outline-none', 'small', 'white']
  const fullModifiers: ITextInputProps['modifiers'] = ['white']

  const customInputProps: Partial<ITextInputProps> = {
    placeholder,
    type: 'text',
    modifiers: isMobile ? mobileModifiers : fullModifiers,
  }

  return (
    <SearchInputWithSuggestions
      ref={inputRef}
      className="inputsSearch"
      name="searchText"
      customInputProps={customInputProps}
      shouldOpenInModal={isMobile}
      customInputWrapper={SearchInputWrapper}
      onInput={onSearchInput}
      onChange={onChange}
      onSuggestChange={onSuggestionChange}
    />
  )
}

export default memo(InputsSearchBar)
