import React, { FC, memo } from 'react'
import { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import { AgriculturalProductPromoLabel } from '@/components/composed/catalog/promo'
import { EAgriculturalProductPromoLabelSize } from '@/components/composed/catalog/promo/AgriculturalProductPromoLabel/interfaces'
import { IBadgeProps } from './types'

const Badge: FC<IBadgeProps> = props => {
  const { className, text, url, color = EChipModifier.LIGHT_GREEN } = props

  return (
    <AgriculturalProductPromoLabel
      className={className}
      text={text}
      color={color}
      size={EAgriculturalProductPromoLabelSize.SMALL}
      url={url}
      shouldShowLabel
    />
  )
}

export default memo(Badge)
