import React, { FC } from 'react'
import { useOnProductCardsViewGtmEvent, useProductCardInteractGtmEvents } from '@/logic/metrika/index/catalog/productCards'
import { CardSliderBlock, IndexPageSection, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import { ISingleAdditionalOfferProps } from './types'
import './styles.scss'

const SingleAdditionalOffer: FC<ISingleAdditionalOfferProps> = props => {
  const { products, isLoading, offerTitle } = props

  const transformedProducts = products?.map(item => ({ ...item.saleRequestTemplate }))
  const setRefCallback = useOnProductCardsViewGtmEvent({ blockName: 'mainOther', products: transformedProducts })
  const { outputsGtmEvents } = useProductCardInteractGtmEvents({ blockName: 'mainOther' })

  if (!transformedProducts?.length) return null

  return (
    <IndexPageSection>
      <SectionBlock>
        {offerTitle && <SectionHeading header={offerTitle} />}
        <CardSliderBlock
          isLoading={isLoading}
          gtmEvents={{ onViewCardRefCallback: setRefCallback, ...outputsGtmEvents }}
          products={transformedProducts}
          cardOptions={{ outputs: { isWithImage: true } }}
        />
      </SectionBlock>
    </IndexPageSection>
  )
}

export default SingleAdditionalOffer
