import { useCallback, useMemo, useRef, useState } from 'react'
import { useIsomorphicLayoutEffect } from '@frontend/pole-ui/lib/hooks/useIsomorphicLayoutEffect'
import { KeenSliderHooks, KeenSliderOptions, useKeenSlider } from 'keen-slider/react'
import { IUseSliderOptions } from './types'
import { useAdaptiveSlidesPerGroup } from './useAdaptiveSlidesPerGroup'

export const useSlider = (options: IUseSliderOptions) => {
  const { adaptiveSlideOptions, keenSliderOptions, slides } = options
  const baseOptions = useRef(keenSliderOptions)
  const adaptiveOptions = useRef(adaptiveSlideOptions)
  const prevIndex = useRef(0)
  const [slidePosition, setSlidePosition] = useState<'first' | 'last' | undefined>('first')
  const [hideArrows, setHideArrows] = useState(false)
  const [totalSlides, setTotalSlides] = useState(0)
  const [activeSlide, setActiveSlide] = useState(0)
  const slidesPerGroup = useAdaptiveSlidesPerGroup(adaptiveOptions.current)

  const sliderOptions: KeenSliderOptions<{}, {}, KeenSliderHooks> = useMemo(
    () => ({
      ...baseOptions.current,

      loop: baseOptions.current.loop && slides && slides.length > 1,
      updated: slider => {
        if (slider.slides.length !== totalSlides) {
          setTotalSlides(slider.slides.length)
        }

        if (slider.track.details.maxIdx === 0) {
          setHideArrows(true)
        } else {
          setHideArrows(false)
        }
      },
      created: slider => {
        setTotalSlides(slider.slides.length)

        if (slider.track.details.maxIdx === 0) setHideArrows(true)
      },

      slideChanged: slider => {
        if (baseOptions.current.loop) setActiveSlide(slider.track.absToRel(slider.track.details.abs))

        if (slider.track.details.abs === slider.track.details.maxIdx) return setSlidePosition('last')
        if (slider.track.details.abs === 0) return setSlidePosition('first')

        return setSlidePosition(undefined)
      },
      dragEnded: slider => {
        if (slidesPerGroup === 1) return

        const currentIndex = slider.track.details.abs

        if (currentIndex === prevIndex.current) {
          slider.moveToIdx(currentIndex)

          return
        }

        const currentRemainder = currentIndex % slidesPerGroup

        if (currentRemainder !== 0) {
          if (currentRemainder >= slidesPerGroup / 2) {
            slider.moveToIdx(currentIndex + slidesPerGroup - currentRemainder)
            prevIndex.current = currentIndex + slidesPerGroup - currentRemainder
          } else {
            slider.moveToIdx(currentIndex - currentRemainder)
            prevIndex.current = currentIndex - currentRemainder
          }
        }
      },
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slidesPerGroup, slides],
  )

  const [productsSliderRef, instanceRef] = useKeenSlider(sliderOptions, [])

  useIsomorphicLayoutEffect(() => {
    if (slides?.length) {
      instanceRef.current?.update(sliderOptions)
    }
  }, [slides, instanceRef, sliderOptions])

  const onNextClick = useCallback(() => {
    if (slidesPerGroup === 1) {
      instanceRef.current?.next()
    } else {
      const maxIdx = instanceRef.current?.track.details.maxIdx || instanceRef.current?.slides.length || 0
      const desiredIndex = (instanceRef.current?.track.details.abs || 0) + slidesPerGroup
      instanceRef.current?.moveToIdx(desiredIndex > maxIdx ? maxIdx : desiredIndex)
    }
  }, [slidesPerGroup, instanceRef])

  const onPrevClick = useCallback(() => {
    if (slidesPerGroup === 1) {
      instanceRef.current?.prev()
    } else {
      const minIdx = instanceRef.current?.track.details.minIdx || 0
      const desiredIndex = (instanceRef.current?.track.details.abs || 0) - slidesPerGroup

      instanceRef.current?.moveToIdx(desiredIndex < minIdx ? minIdx : desiredIndex)
    }
  }, [slidesPerGroup, instanceRef])

  const onPaginationDotClick = useCallback(
    (index: number) => {
      instanceRef.current?.moveToIdx(index)
    },
    [instanceRef],
  )

  return {
    productsSliderRef,
    totalSlides,
    activeSlide,
    control: {
      onNextClick,
      onPrevClick,
      onPaginationDotClick,
      showBackArrow: !hideArrows && slidePosition !== 'first',
      showForwardArrow: !hideArrows && slidePosition !== 'last',
    },
  }
}
