import { TMessages } from '@/types/TMessages'

export const messages = {
  blockTitle: 'Вашу заявку ждут',
  yourApplicationWaits: 'в {regions} регио{regions, plural, one {не} other {нах}}',
  noPriceAdvice: 'Создайте заявку, чтобы покупатели рассмотрели ваше предложение',
  topNoticePrice: 'Среднерыночная цена',
  minMaxPrices: '{minPrice} — {maxPrice} ₽/т',
  topNoticeAdvice: 'Создайте заявку с ценой в пределах среднерыночной, чтобы получить больше предложений покупателей',
  currentPrice: '{price} руб./т',
} satisfies TMessages
