import React, { FC } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Image } from '@/components/ui'
import { IManufacturerCardProps } from './types'
import './styles.scss'

const ManufacturerCard: FC<IManufacturerCardProps> = props => {
  const { brand, onClick } = props
  const { url, image } = brand

  return (
    <CommonLink spanIfEmpty className="manufacturerCard" url={url} onClick={onClick}>
      <Image className="manufacturerCard__img" src={image} />
    </CommonLink>
  )
}

export default ManufacturerCard
