import React, { FC } from 'react'
import { TextWithIcon } from '@/components/ui'
import { createItems } from '@/utils/helpers'
import { messages } from '../../messages'
import { CONDITION_ICON_PROPS, NUMBER_OF_CONDITIONS } from './constants'
import './styles.scss'

const ConsultingConditions: FC = () => {
  return (
    <div className="consultingConditions">
      {createItems(NUMBER_OF_CONDITIONS, index => (
        <TextWithIcon
          className="consultingConditions__item"
          text={messages.condition[String(index + 1) as keyof typeof messages.condition]}
          iconProps={CONDITION_ICON_PROPS}
          textModifier="base"
          textClassName="text_small-md"
          key={index}
        />
      ))}
    </div>
  )
}

export default ConsultingConditions
