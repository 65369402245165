import { useCallback, useEffect, useState } from 'react'
import { IIntesectionObserverProps } from '@/utils/hooks/index/types'

export const useObserveElements = (props: IIntesectionObserverProps) => {
  const { observableElements, attachedRefId, onIntersect, shouldSubmitOnEachView } = props
  const [viewedProducts, setViewedProducts] = useState<string[]>([])

  const callback = useCallback((entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (!shouldSubmitOnEachView) observer.unobserve(entry.target)

        if (entry.target instanceof HTMLElement && entry.target.dataset.elementId) {
          const elementId = entry.target.dataset.elementId
          onIntersect?.(elementId)

          if (!shouldSubmitOnEachView) setViewedProducts(productsIds => [...productsIds, elementId])
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!attachedRefId || !observableElements) return

    const values = Object.values(observableElements)

    if (values.length < 1) return

    const observer = new IntersectionObserver(callback, { threshold: 0 })
    values.forEach(el => observer.observe(el))

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachedRefId])

  return viewedProducts
}
