import React, { FC } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { Image } from '@/components/ui'
import { ILoanCardProps } from './types'
import './styles.scss'

export const LoanCard: FC<ILoanCardProps> = props => {
  const { loan, image, onClick } = props
  const { title, clause1, clause2, url } = loan

  return (
    <article className="loanCard">
      <CommonLink spanIfEmpty className="loanCard__linkOverlay" url={url} onClick={onClick} />
      <div>
        <Image src={image} className="loanCard__image" />
      </div>
      <div className="loanCard__title">
        <h4 className="h3-sm">{title}</h4>
        <Icon name={EIconName.ShevronRight} className="loanCard__icon" />
      </div>

      <div className="loanCard__divider" />
      <ul className="loanCard__benefitsList">
        <li className="loanCard__benefitsItem">{clause1}</li>
        <li className="loanCard__benefitsItem">{clause2}</li>
      </ul>
    </article>
  )
}
