import { useDispatch, useSelector } from 'react-redux'
import { CultureData } from '@/api/generated/base'
import { openModal } from '@/components/composed/Modal/actions'
import { isUserAgrarian } from '@/logic/auth/reducer'
import { IRequestHarvestSaleByTemplateFormValues } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/interfaces'
import { ISaleRequestFormData } from '@/pages/IndexPage/sections/Outputs/components/SaleRequestForm/types'
import { RootState } from '@/redux/interfaces'
import { EOrderType } from '@/types/HarvestSaleLots'
import { useActionWithAuthorization } from '@/utils/hooks/useActionWithAuthorization'
import { useCurrentRegion } from '@/utils/outputs/currentRegion'

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
})

export const useSaleRequestSubmit = () => {
  const { region } = useCurrentRegion()

  const { isAgrarian } = useSelector(mapState)

  const dispatch = useDispatch()

  const { makeAuthorizedAction } = useActionWithAuthorization<
    (values: Partial<ISaleRequestFormData>, cultureId: CultureData['id']) => void
  >((values, cultureId) => {
    dispatch(
      openModal({
        options: {
          dialogId: 'RequestHarvestSaleByTemplateModal',
        },
        contentOptions: {
          currentCultureId: cultureId,
          customInitialFormValues: {
            cultureId,
            desiredPrice: values.desiredPrice,
            deliveryOptions: { elevatorId: values.elevatorId, deliveryBasisId: values.deliveryBasis, storeId: values.supplierStoreId },
            volume: values.goodsVolume,
            quality: { proteinFrom: values.proteinFrom },
            orderType: EOrderType.multiple,
            vatUsed: false,
            regionCode: values.elevatorRegion || region?.code,
          } as Partial<IRequestHarvestSaleByTemplateFormValues>,
          isOwnOffer: true,
        },
      }),
    )
  }, isAgrarian)

  return { onSaleRequestSubmit: makeAuthorizedAction }
}
