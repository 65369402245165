import { TCatalogType } from '@/logic/index/types'

export const POPULAR_QUERIES: Record<TCatalogType, string[]> = {
  machinery: [
    'Запасные части',
    'Новая сельхозтехника',
    'Техника с наработкой',
    'Горюче-смазочные материалы',
    'Шины и диски',
    'Оборудование',
    'Сельскохозяйственные сооружения',
  ],
  agroproducts: ['Глифосат', 'Гербициды', 'Фунгициды', 'Селитра', 'Аммофос', 'Кормовые добавки', 'Семена подсолнечника', 'Семена кукурузы'],
}
