import { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import { v4 as uuid } from 'uuid'
import { IProductCardLabel } from '@/components/ui/inputs/OfferLabels/interfaces'
import { formatDate } from '@/utils/formatUtils'
import { messages } from './messages'

export const useValidToBadge = (validTo?: string): IProductCardLabel[] => {
  if (!validTo) return []

  return [
    {
      id: uuid(),
      name: `${messages.validTo} ${formatDate(validTo, 'dd.MM.YY')}`,
      color: EChipModifier.STATUS_INFO,
    },
  ]
}
