import React, { memo } from 'react'
import { GetMainPageBannersQueryParamsType, useGetMainPageBanners } from '@/api/kubik/monolith'
import { useIndexBannerGtmEvent } from '@/logic/metrika/index/banners/gtmEvents'
import { PromoSliderBlock } from '@/pages/IndexPage/components'
import { IOutputsBannerProps } from './types'
import './styles.scss'

const Banners = (props: IOutputsBannerProps) => {
  const { region } = props

  const {
    data: bannerData,
    isLoading,
    isSuccess,
    isError,
  } = useGetMainPageBanners({ regionCode: region.code, type: GetMainPageBannersQueryParamsType.OUTPUTS })

  const { clickBannerGtmEvent, setRefCallback } = useIndexBannerGtmEvent('blockBannerMain5')

  const shouldHideBlock = (isSuccess && !bannerData.data?.banners.length) || isError

  if (shouldHideBlock) return null

  return (
    <PromoSliderBlock
      isLoading={isLoading}
      slideClassName="outputsBanners__slide"
      promoBanners={bannerData?.data?.banners}
      visibleItems={1}
      onViewCardRefCallback={setRefCallback}
      onBannerClickGtmEvent={clickBannerGtmEvent}
    />
  )
}

export default memo(Banners)
