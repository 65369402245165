import React, { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { getUserRegion } from '@/logic/userRegion'
import { IndexPageSection, News } from '@/pages/IndexPage/components'
import { RootState } from '@/redux/interfaces'
import { BestPromoOffers, ProductsCatalog, ProductsPromoBanner } from './components'
import './styles.scss'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
})

const Inputs: FC = () => {
  const { userRegion } = useSelector(mapState)

  return (
    <>
      <IndexPageSection>
        <ProductsCatalog type="agroproducts" />
      </IndexPageSection>
      <IndexPageSection>
        <BestPromoOffers region={userRegion} />
      </IndexPageSection>
      <IndexPageSection>
        <ProductsPromoBanner type="agricultural" region={userRegion} />
      </IndexPageSection>
      <IndexPageSection>
        <ProductsCatalog type="machinery" />
      </IndexPageSection>
      <IndexPageSection>
        <ProductsPromoBanner type="machinery" region={userRegion} />
      </IndexPageSection>
      <IndexPageSection backgroundColor="grey">
        <News type="inputs" />
      </IndexPageSection>
    </>
  )
}

export default memo(Inputs)
