import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { StoreInput } from '@/components/composed'
import { openModal } from '@/components/composed/Modal/actions'
import { Button } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { Dropdown, TextInput } from '@/components/ui/ReactHookFormFields'
import { culturesWithImportantProtein } from '@/constants/outputs'
import { saleRequestFormVolumeLimits } from '@/pages/CulturesSaleLots/constants'
import { ECultureId } from '@/types'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import { formatLocalizedString } from '@/utils/formatUtils'
import { usePlacementOptions } from '@/utils/hooks/outputs'
import { PROTEIN_MAX_VALUE, PROTEIN_MIN_VALUE } from '../../consts'
import { validateProteinPercentage } from '../../helpers'
import { ISaleRequestFormData } from '../../types'
import { useCultureSaleApi } from './hooks'
import { messages } from './messages'
import { ICultureSaleFormProps } from './types'
import './styles.scss'

const CultureSaleForm: FC<ICultureSaleFormProps> = props => {
  const { buyersAndPrices, culture } = props

  const { isAuthenticated } = useAuthenticateUser()
  const dispatch = useDispatch()

  const { control, watch, setValue, trigger } = useFormContext<ISaleRequestFormData>()

  const [deliveryBasisInput, elevatorRegionCodeInput, supplierStoreIdInput] = watch(['deliveryBasis', 'elevatorRegion', 'supplierStoreId'])
  const { data } = useCultureSaleApi(culture.id, elevatorRegionCodeInput)

  const isCurrentRegion = elevatorRegionCodeInput || supplierStoreIdInput

  const { regionOptions, elevatorOptions, isElevatorsLoading, isRegionsLoading, locationOptions } = usePlacementOptions(
    elevatorRegionCodeInput,
    undefined,
    deliveryBasisInput,
  )

  const openBuyersNoticeModal = () => dispatch(openModal({ options: { dialogId: 'NoBuyersModal' } }))

  const isWagonDeliveryBasis = deliveryBasisInput === EDeliveryBasisVariantId.wagon
  const shouldChooseStore = deliveryBasisInput === EDeliveryBasisVariantId.pickup || isWagonDeliveryBasis
  const shouldChooseElevator =
    deliveryBasisInput === EDeliveryBasisVariantId.elevator ||
    deliveryBasisInput === EDeliveryBasisVariantId.exw ||
    deliveryBasisInput === EDeliveryBasisVariantId.fob

  return (
    <div className="requestFormWrapper">
      <h5 className="requestFormWrapper__cultureName">{culture.name}</h5>
      <div className="requestFormWrapper__inputs">
        {/* Базис */}
        <Dropdown
          control={control}
          name="deliveryBasis"
          options={data.deliveryBasisOptions}
          label={messages.deliveryBasisLabel}
          modifiers={['white']}
        />
        {/* Адрес */}
        {shouldChooseStore && isAuthenticated && (
          <StoreInput
            name="supplierStoreId"
            labelId={isWagonDeliveryBasis ? messages.supplierStoreLabel : messages.yourStoreLabel}
            onAddNewLocation={storeId => setValue('supplierStoreId', storeId.id)}
            options={locationOptions}
            control={control}
            context={isWagonDeliveryBasis ? 'wagon' : undefined}
            position={'page'}
            dropdownProps={{ modifiers: ['white'] }}
          />
        )}

        {/* Элеватор */}
        {shouldChooseElevator && (
          <>
            <Dropdown
              name="elevatorRegion"
              options={regionOptions as IDropdownOption<string>[]}
              label={messages.elevatorRegionLabel}
              control={control}
              isDisabled={isRegionsLoading}
              isSearchable
              modifiers={['white']}
            />
            <Dropdown
              name="elevatorId"
              label={messages.elevatorIdLabel}
              options={elevatorOptions}
              control={control}
              isDisabled={isElevatorsLoading || !elevatorRegionCodeInput}
              isSearchable
              modifiers={['white']}
            />
          </>
        )}
        {/* Показатели культуры - только протеин */}
        {culturesWithImportantProtein.includes(culture.id as ECultureId) && (
          <TextInput
            name="proteinFrom"
            label={messages.proteinLabel}
            control={control}
            modifiers={['white']}
            onChange={() => {
              trigger('proteinFrom')
            }}
            rules={{
              validate: value =>
                !value ||
                validateProteinPercentage(value, PROTEIN_MIN_VALUE, PROTEIN_MAX_VALUE) ||
                formatLocalizedString(messages.proteinIncorrectNumberErrorText, {
                  minValue: PROTEIN_MIN_VALUE,
                  maxValue: PROTEIN_MAX_VALUE,
                }),
            }}
          >
            <span className="requestFormWrapper__unitLabel">{messages.proteinUnit}</span>
          </TextInput>
        )}

        {/* Объем партии */}
        <TextInput
          name="goodsVolume"
          label={messages.volumeLabel}
          type="price"
          control={control}
          modifiers={['white']}
          shouldBeTouchedBeforeError={false}
          rules={{
            required: messages.requiredFieldErrorText,
          }}
          minValue={saleRequestFormVolumeLimits.min}
          maxValue={saleRequestFormVolumeLimits.max}
        >
          <span>{messages.volumeUnits}</span>
        </TextInput>

        {/* Желаемая цена */}
        <TextInput name="desiredPrice" label={messages.desiredPriceLabel} type="floatPrice" control={control} modifiers={['white']}>
          <span className="requestFormWrapper__unitLabel">{messages.priceUnit}</span>
        </TextInput>
      </div>
      <div className="requestFormWrapper__footer">
        {isCurrentRegion && !buyersAndPrices?.buyersInGivenRegionPresent ? (
          <>
            <div className="requestFormWrapper__footerNotice">
              <Icon name={EIconName.WarningFill} style={{ color: EColor.ORANGE }} className="requestFormWrapper__footerIcon" />
              <p className="requestFormWrapper__footerCaption">{messages.noBuyersAlertLabel}</p>
            </div>
            {/* Кнопка хочу больше покупателей */}
            <Button modifiers={['outline']} onClick={openBuyersNoticeModal} className="requestFormWrapper__footerMoreButton">
              {messages.noBuyersButton}
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            modifiers={['green', 'small', 'full-width-mobile']}
            iconPosition="right"
            onClick={() => {
              trigger('goodsVolume', { shouldFocus: true })
            }}
            iconProps={{ name: EIconName.ShevronRight }}
            className="requestFormWrapper__footerSubmitButton"
          >
            {messages.createRequest}
          </Button>
        )}
      </div>
    </div>
  )
}

export default CultureSaleForm
