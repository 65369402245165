import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Image } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { MATCH_MEDIA_QUERIES } from '@/constants/common'
import { getStaticImagePath } from '@/utils/images'
import './styles.scss'

const BecomePartners = () => {
  const { openAuthModal } = useAuthenticateUser()

  return (
    <div className="becomePartners">
      <CommonLink spanIfEmpty url="partners/start#seller">
        <picture>
          <source srcSet={getStaticImagePath('index/ifa/becomePartner1-mob.png')} media={MATCH_MEDIA_QUERIES.mobile} />
          <Image src="index/ifa/becomePartner1-full.png" isInStatic className="becomePartners__banner" />
        </picture>
      </CommonLink>
      <CommonLink spanIfEmpty onClick={openAuthModal}>
        <picture>
          <source srcSet={getStaticImagePath('index/ifa/becomePartner2-mob.png')} media={MATCH_MEDIA_QUERIES.mobile} />
          <Image src="index/ifa/becomePartner2-full.png" isInStatic className="becomePartners__banner" />
        </picture>
      </CommonLink>
    </div>
  )
}

export default BecomePartners
