import React, { FC } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Image } from '@/components/ui'
import { EJournalRoute } from '@/types'
import { useFormatDate } from '@/utils/hooks/format/useFormatDate'
import { createRouterLink } from '@/utils/url'
import { INewsCardProps } from './types'
import './styles.scss'

const NewsCard: FC<INewsCardProps> = props => {
  const { news, onClick } = props
  const { title, coverImage, creationDate } = news

  const { formatDate } = useFormatDate()

  const creationDateFormat = formatDate(creationDate, 'dd MMMM')

  return (
    <CommonLink
      className="newsCard"
      url={createRouterLink(EJournalRoute.PUBLICATION_ITEM, { publicationSlug: news.slug })}
      onClick={onClick}
    >
      <Image src={coverImage?.squareCover} className="newsCard__img" />
      <div>
        <p className="h5">{title}</p>
        <span className="text_small text_super-small-sm newsCard__date">{creationDateFormat}</span>
      </div>
    </CommonLink>
  )
}

export default NewsCard
