import React, { FC } from 'react'
import { useGetBrandsList } from '@/api/kubik/monolith'
import { useManufacturersGtmEvents } from '@/logic/metrika/index/inputs/manufacturers'
import { ProductsCount, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import { getOffersNumber } from '@/utils/indexPage/getOffersNumber'
import { ManufacturerCard } from './components'
import { messages } from './messages'
import './styles.scss'

const Manufacturers: FC = () => {
  const { data: brands, isSuccess, isError } = useGetBrandsList()

  const { onManufacturerCardClickGtmEvent } = useManufacturersGtmEvents()

  const shouldHideBlock = (isSuccess && !brands.data?.brands.length) || isError

  if (shouldHideBlock) return null

  return (
    <>
      <SectionBlock>
        <SectionHeading header={messages.header}>
          <ProductsCount>{getOffersNumber({ type: 'manufacturers', offers: brands?.data?.brandsCount })}</ProductsCount>
        </SectionHeading>
      </SectionBlock>

      <SectionBlock withoutRightMarginMobile>
        <div className="manufacturers__list">
          {brands?.data?.brands.map((brand, index) => (
            <ManufacturerCard key={index} brand={brand} onClick={() => onManufacturerCardClickGtmEvent(brand.url)} />
          ))}
        </div>
      </SectionBlock>
    </>
  )
}

export default Manufacturers
