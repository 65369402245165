import React from 'react'
import { useSectionHeaderGtmEvent } from '@/logic/metrika/index/sectionHeaders/gtmEvents'
import { THeaderBlockName } from '@/logic/metrika/index/sectionHeaders/types'
import { InputsSearchBar, ProductsCount, SearchInputPopularQueries, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import { getOffersNumber } from '@/utils/indexPage/getOffersNumber'
import { useMainPageCatalog } from '../hooks'
import { HEADER_URL } from './constants'
import { messages } from './messages'
import { ICatalogHeaderProps } from './types'
import './styles.scss'

const CatalogHeader = (props: ICatalogHeaderProps) => {
  const { type } = props
  const { categories } = useMainPageCatalog(type)

  const headerBlockName: THeaderBlockName = type === 'agroproducts' ? 'blockInputsFarming' : 'blockInputsMachinery'

  const { onClickSectionHeaderGtmEvent } = useSectionHeaderGtmEvent(headerBlockName)

  return (
    <>
      <SectionBlock>
        <SectionHeading
          header={messages.header[type]}
          subHeader={messages.subheader[type]}
          onClick={onClickSectionHeaderGtmEvent}
          url={HEADER_URL[type]}
        >
          <ProductsCount>{getOffersNumber({ offers: categories?.total, type: 'inputs' })}</ProductsCount>
        </SectionHeading>
        <InputsSearchBar placeholder={messages.placeholder[type]} />
      </SectionBlock>
      <SectionBlock withoutRightMarginMobile className="catalogHeader__queriesWrapper">
        <SearchInputPopularQueries type={type} />
      </SectionBlock>
    </>
  )
}

export default CatalogHeader
