import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { BubblingTooltip } from '@/components/ui'
import RecommendedPriceNotice from '@/pages/CulturesSaleLots/components/CultureSaleRequest/RecommendedPriceNotice'
import { ISaleRequestFormData } from '@/pages/IndexPage/sections/Outputs/components/SaleRequestForm/types'
import { ECultureId } from '@/types'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import { formatLocalizedString } from '@/utils/formatUtils'
import { messages } from './messages'
import { IPricePredictionWidgetProps } from './types'
import './styles.scss'

const PricePredictionWidget: FC<IPricePredictionWidgetProps> = props => {
  const { culture, buyersAndPrices } = props
  const { watch } = useFormContext<ISaleRequestFormData>()

  const [elevatorRegion, deliveryBasis, supplierStoreId, proteinFrom] = watch([
    'elevatorRegion',
    'deliveryBasis',
    'supplierStoreId',
    'proteinFrom',
  ])

  const isFCA = deliveryBasis === EDeliveryBasisVariantId.pickup

  const minPrice = buyersAndPrices?.minPrice?.value
  const maxPrice = buyersAndPrices?.maxPrice?.value

  const withPrice = Boolean(minPrice && maxPrice) && minPrice !== Infinity && maxPrice !== Infinity
  const recommendedPrice = Boolean(minPrice && maxPrice) && minPrice === maxPrice
  const isCurrentRegion = elevatorRegion || supplierStoreId
  const buyersInCurrentRegion = buyersAndPrices?.buyersInGivenRegionPresent === true

  const shouldShowMinMaxPrice = withPrice && !recommendedPrice && isFCA
  const shouldShowMaxPrice = recommendedPrice && isFCA
  const shouldShowPriceBlock = shouldShowMinMaxPrice || shouldShowMaxPrice

  const minMaxAddressNotChoosenCondition = !isCurrentRegion && !proteinFrom
  const maxAddressNotChosenCondition = !isCurrentRegion
  const isAddressNotChosenCondition = shouldShowMinMaxPrice ? minMaxAddressNotChoosenCondition : maxAddressNotChosenCondition

  return (
    <div className="pricePredictionWidget">
      <div className="pricePredictionWidget__textBlock">
        <p className="pricePredictionWidget__headerText text_small">{messages.blockTitle}</p>
        <p className="h5-from-sm text_super-small-sm pricePredictionWidget__applications">
          {formatLocalizedString(messages.yourApplicationWaits, { regions: buyersAndPrices?.regionsNumber })}
        </p>
      </div>
      {!withPrice && !recommendedPrice && isFCA && <p className="pricePredictionWidget__noPrice text_small">{messages.noPriceAdvice}</p>}

      {shouldShowPriceBlock && (
        <>
          <div className="pricePredictionWidget__textBlock">
            <div className="pricePredictionWidget__priceHeader">
              <p className="pricePredictionWidget__headerText text_small">{messages.topNoticePrice}</p>
              <BubblingTooltip
                content={
                  <RecommendedPriceNotice
                    isAddressNotChosen={isAddressNotChosenCondition}
                    isActualPrice={
                      (recommendedPrice && buyersInCurrentRegion && culture?.id !== ECultureId.WHEAT) || (!!proteinFrom && recommendedPrice)
                    }
                    isNoActualPrice={
                      (buyersInCurrentRegion && !recommendedPrice && culture?.id !== ECultureId.WHEAT) ||
                      (!!proteinFrom && !recommendedPrice)
                    }
                  />
                }
              >
                <span>
                  <Icon name={EIconName.QuestFill} style={{ opacity: '0.2' }} />
                </span>
              </BubblingTooltip>
            </div>

            <p className="h5-from-sm text_super-small-sm pricePredictionWidget__price">
              {shouldShowMinMaxPrice
                ? formatLocalizedString(messages.minMaxPrices, { minPrice, maxPrice })
                : formatLocalizedString(messages.currentPrice, { price: maxPrice })}
            </p>
            <p className="pricePredictionWidget__notice text_small">{messages.topNoticeAdvice}</p>
          </div>
        </>
      )}
    </div>
  )
}

export default PricePredictionWidget
