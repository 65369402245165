import React, { FC, memo, useMemo } from 'react'
import { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import ConsultingForm from '@/components/composed/ConsultingForm'
import { HeadingVariant } from '@/components/composed/ConsultingForm/constants'
import { EGtmProductName } from '@/logic/metrika/financing/types'
import { messages } from '../../messages'
import { PURPOSE_OPTIONS } from './constants'
import { IFinancingConsultingFormProps } from './types'
import './styles.scss'

const FinancingConsultingForm: FC<IFinancingConsultingFormProps> = props => {
  const { firstInputRef, footerClassName, footerLinkClassName, headingVariant = HeadingVariant.FINANCING_CONSULTING } = props

  const purposeOptions = useMemo<IDropdownOption[]>(
    () =>
      PURPOSE_OPTIONS.map(key => {
        const label = messages.form.purpose[key as keyof typeof messages.form.purpose]

        return { label, value: label }
      }),
    [],
  )

  return (
    <div className="financingConsultingForm">
      <ConsultingForm
        purposeOptions={purposeOptions}
        gtmProductName={EGtmProductName.MARKETPLACE}
        firstInputRef={firstInputRef}
        headingVariant={headingVariant}
        footerClassName={footerClassName}
        footerLinkClassName={footerLinkClassName}
      />
    </div>
  )
}

export default memo(FinancingConsultingForm)
