import React, { FC, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { openModal } from '@/components/composed/Modal/actions'
import { addModalToWellKnown } from '@/components/composed/Modal/constants'
import { Button, Image, LotPrices, LotQualities } from '@/components/ui'
import { OfferLabels } from '@/components/ui/inputs'
import { isMobile } from '@/logic/app/reducer'
import { isUserAgrarian } from '@/logic/auth/reducer'
import { RequestHarvestSaleByTemplateModal } from '@/pages/HarvestSaleLots/components/'
import { IRequestHarvestSaleByTemplateModalOptions } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/interfaces'
import { useValidToBadge } from '@/pages/IndexPage/components/ItemCards/OutputsCard/hooks'
import { RootState } from '@/redux/interfaces'
import { ITemplate } from '@/types/HarvestSaleLots'
import { useActionWithAuthorization } from '@/utils/hooks/useActionWithAuthorization'
import { getCropsImage, handleTemlateApiResponse } from './helpers'
import { messages } from './messages'
import { IOutputsCardProps } from './types'
import './styles.scss'

addModalToWellKnown({ RequestHarvestSaleByTemplateModal })

const mapState = (state: RootState) => ({
  isMobileDevice: isMobile()(state),
  isAgrarian: isUserAgrarian()(state),
})

const OutputsCard: FC<IOutputsCardProps> = props => {
  const { item, options, onClickOutputsOfferReactGtmEvent } = props
  const { id, validTo, qualityIndicators, buyer, culture } = item
  const { name: buyerName } = buyer
  const { id: cultureName } = culture

  const byuerRegion = item.elevator?.location.region.name

  const dispatch = useDispatch()
  const { isMobileDevice, isAgrarian } = useSelector(mapState)

  const image = getCropsImage(cultureName)

  const itemBadge = useValidToBadge(validTo)

  const openHarvestSaleModal = (contentOptions: IRequestHarvestSaleByTemplateModalOptions) =>
    dispatch(
      openModal({
        options: {
          dialogId: 'RequestHarvestSaleByTemplateModal',
        },
        contentOptions,
      }),
    )

  const { makeAuthorizedAction, isAuthenticated, isActionPermitted } = useActionWithAuthorization(() => {
    onClickOutputsOfferReactGtmEvent?.(isActionPermitted)

    // TODO: OUT-1115 После рефакторинга типизации openHarvestModal убрать handleTemlateApiResponse и напрямую передавать item
    const transformedTemplate = handleTemlateApiResponse(item)
    //
    openHarvestSaleModal({ template: transformedTemplate, initialCultureId: id })
  }, isAgrarian)

  return (
    <article className="outputsCard">
      <div className={classNames('outputsCard__imageBlock', options?.isWithBadge && 'outputsCard__imageBlock--fixedHeight')}>
        {options?.isWithImage && <Image src={image} isInStatic />}
        <OfferLabels
          className={classNames(options?.isWithImage ? 'outputsCard__label-imageOverlay' : 'outputsCard__label')}
          list={itemBadge}
          isColumn
          isMobileDevice={isMobileDevice}
        />
      </div>
      <div className="outputsCard__title">{culture.name}</div>
      <LotQualities className="outputsCard__qualityIndicators text_small" quality={qualityIndicators} />
      <LotPrices
        className="outputsCard__lotPrices"
        displayVariant="index"
        isAgrarian={isAgrarian}
        isMobileDevice={isMobileDevice}
        isStoreChosen={false}
        template={item as ITemplate}
        perUnitTextId="т"
      />
      <div className="text_super-small outputsCard__buyer">{`${buyerName}${byuerRegion ? `, ${byuerRegion}` : ''}`}</div>

      <div className="outputsCard__footer">
        <Button
          modifiers={['green', 'small']}
          className="outputsCard__applyBtn"
          onClick={makeAuthorizedAction}
          isHidden={Boolean(isAuthenticated) && !isAgrarian}
        >
          {messages.sendRequest}
        </Button>
        <p className="outputsCard__priceDetails text_super-small">{messages.noVat}</p>
      </div>
    </article>
  )
}

export default memo(OutputsCard)
