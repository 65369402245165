import React, { FC, memo } from 'react'
import { useSelectCategoryGtmEvent } from '@/logic/metrika/index/catalog/categorySelector'
import { CategorySelector, CultureSaleCard, SectionBlock } from '@/pages/IndexPage/components'
import { useOutputsCategories } from './hooks'
import './styles.scss'

const Catalog: FC = () => {
  const { isLoading, outputsCategories, totalProducts, isError, isSuccess, currentCategory, selectCategory, catogoryIndex } =
    useOutputsCategories()

  const { onCategorySelectGtmEvent } = useSelectCategoryGtmEvent('blockOutputs')

  const shouldHideBlock = (isSuccess && !outputsCategories?.length) || isError || !currentCategory

  if (shouldHideBlock) return null

  const onSelectCategory = (index: number) => {
    selectCategory(index)

    if (currentCategory) onCategorySelectGtmEvent(currentCategory.id)
  }

  return (
    <>
      <SectionBlock withoutRightMarginMobile>
        <CategorySelector
          currentCategory={catogoryIndex}
          isLoading={isLoading}
          type="outputs"
          setCategory={onSelectCategory}
          productsCount={totalProducts}
          categories={outputsCategories}
        />
      </SectionBlock>
      <SectionBlock>
        <CultureSaleCard culture={currentCategory} />
      </SectionBlock>
    </>
  )
}

export default memo(Catalog)
