import { useCallback, useRef, useState } from 'react'
import { TViewCardSetRefProps } from './types'

export const useSetElementsRef = () => {
  const cardRef = useRef<Record<string, HTMLDivElement>>({})
  const [refId, setRefId] = useState('')

  const setRefCallback = useCallback((props: TViewCardSetRefProps) => {
    const { index, node, productId, isSingleElement } = props

    if (node) {
      if (!node.dataset?.elementId) node.setAttribute('data-element-id', productId)

      if ((index && index === 0) || isSingleElement) setRefId(productId)

      cardRef.current[productId] = node
    } else {
      delete cardRef.current[productId]
    }
  }, [])

  return { observableElements: cardRef.current, attachedRefId: refId, setRefCallback }
}
