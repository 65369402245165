import React from 'react'
import classNames from 'classnames'
import { IPageBlockProps } from './types'
import './styles.scss'

const SectionBlock = (props: IPageBlockProps) => {
  const { children, withoutRightMarginMobile, className } = props

  return <div className={classNames('page-wrapper', { 'pageBlock--noRight': withoutRightMarginMobile }, className)}>{children}</div>
}

export default SectionBlock
