import React, { FC, memo } from 'react'
import { IndexPageSection, SectionBlock, SectionHeading } from '@/pages/IndexPage/components'
import { BecomePartners, Manufacturers, OurPartners } from './components'
import { messages } from './messages'

const Partners: FC = () => {
  return (
    <>
      <IndexPageSection backgroundColor="grey">
        <SectionBlock>
          <SectionHeading header={messages.becomePartnersHeader} />
          <BecomePartners />
        </SectionBlock>
      </IndexPageSection>

      <IndexPageSection>
        <SectionBlock>
          <SectionHeading header={messages.ourParntersHeader} />
        </SectionBlock>
        <SectionBlock withoutRightMarginMobile>
          <OurPartners />
        </SectionBlock>
      </IndexPageSection>

      <IndexPageSection>
        <Manufacturers />
      </IndexPageSection>
    </>
  )
}

export default memo(Partners)
