import { useHistory } from 'react-router-dom'
import { EAgriculturalProductsRoute } from '@/types'
import { EAgriculturalProductCategoryId, IAsyncThunkFetchProductCatalogPayload } from '@/types/Catalog'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'

export const useCatalogSearch = () => {
  const history = useHistory()

  const onSearch = (query: string) => {
    const { params, search }: IAsyncThunkFetchProductCatalogPayload = {
      params: { categoryId: String(EAgriculturalProductCategoryId.SEARCH) },
      search: { searchText: query },
    }

    const url = getUrlWithQuery(createRouterLink(EAgriculturalProductsRoute.category, params), search)

    history.push(url)
  }

  return { onSearch }
}
