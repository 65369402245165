import React from 'react'
import Swiper from 'react-id-swiper/lib/ReactIdSwiper'
import { ReactIdSwiperProps } from 'react-id-swiper/lib/types'
import { Skeleton } from '@frontend/pole-ui/lib/components/Skeleton'
import classNames from 'classnames'
import { SwiperArrow } from '@/components/ui'
import { useSwiper } from '@/components/ui/SwiperArrows/useSwiper'
import { useSwiperArrows } from '@/utils/hooks/index/useSwiperArrows'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { getOffersNumber } from '@/utils/indexPage/getOffersNumber'
import { skeletons } from './constants'
import { messages } from './messages'
import { ICategorySelectorProps } from './types'
import './styles.scss'

const CategorySelector = <T extends { id?: string | number; name?: string }>(props: ICategorySelectorProps<T>) => {
  const { type, setCategory, categories, isLoading, productsCount, currentCategory } = props

  const { isMobile } = useDeviceType()

  const { swiperRef, slidePrevious, slideNext } = useSwiper()

  const { arrows, swiperArrowParams } = useSwiperArrows()
  const { isBackArrowVisible, isForwardArrowVisible } = arrows

  const isScrollableCategory = categories && categories.length > 6 && !isMobile
  const shouldShowBackArrow = isBackArrowVisible && isScrollableCategory
  const shouldShowForwardArrow = isForwardArrowVisible && isScrollableCategory

  const swiperParams: ReactIdSwiperProps = {
    slidesPerView: 'auto',
    on: {
      ...swiperArrowParams,
    },
    containerClass: 'categorySlider',
  }
  const categoryBlock = categories?.map((category, index) => {
    const totalProductsInCategory = productsCount?.find(cat => cat.id === category.id)?.count

    return (
      <article
        key={category.id}
        className={classNames('productCategories__card', {
          'productCategories__card--selected': index === currentCategory,
        })}
        onClick={() => setCategory(index)}
      >
        <p className="h4 h5-sm productCategories__cardCategory">{category.name}</p>

        <p className="text_small text_super-small-sm productCategories__cardQuantity">
          {totalProductsInCategory && type !== 'outputs' ? getOffersNumber({ type, offers: totalProductsInCategory }) : messages.noProducts}
        </p>
      </article>
    )
  })

  return (
    <div className="productCategories">
      <Skeleton isLoading={isLoading} skeletons={skeletons}>
        <Swiper ref={swiperRef} {...swiperParams}>
          {categoryBlock}
        </Swiper>
        {shouldShowBackArrow && (
          <SwiperArrow
            modifier="swiper-with-white-background"
            direction="left"
            onClick={slidePrevious}
            className="productCategories__controlArrowItem productCategories__controlArrowItem-left"
          />
        )}
        {shouldShowForwardArrow && (
          <SwiperArrow
            modifier="swiper-with-white-background"
            direction="right"
            onClick={slideNext}
            className="productCategories__controlArrowItem productCategories__controlArrowItem-right"
          />
        )}
      </Skeleton>
    </div>
  )
}

export default CategorySelector
