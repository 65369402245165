import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Image } from '@/components/ui'
import { MATCH_MEDIA_QUERIES } from '@/constants/common'
import { getStaticImagePath } from '@/utils/images'
import { ILoanBannerProps } from './types'
import './styles.scss'

const LoanBanner = (props: ILoanBannerProps) => {
  const { className } = props

  return (
    <div className="loanBanner">
      <CommonLink url="/finance">
        <picture>
          <source srcSet={getStaticImagePath('index/ifa/ifaBanner-mob.jpg')} media={MATCH_MEDIA_QUERIES.mobile} />
          <Image src="/index/ifa/ifaBanner-full.jpg" isInStatic className={classNames('loanBanner__image', className)} />
        </picture>
      </CommonLink>
    </div>
  )
}

export default LoanBanner
