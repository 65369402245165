import { useSelector } from 'react-redux'
import { getUserGtmInfo, pushGtmEvent } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { RootState } from '@/redux/interfaces'

const mapState = (state: RootState) => ({
  user: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const useFinancingGtmEvents = () => {
  const { user, userRegion } = useSelector(mapState)

  const onProductCardClickGtmEvent = (product: string) => {
    pushGtmEvent({
      event: 'userEvent',
      userAuth: user.userAuth,
      userImpersonalization: user.userImpersonalization,
      memberID: user.memberID,
      uid: user.uid,
      regionSelected: userRegion,
      eventCategory: 'interactions',
      eventAction: 'click',
      eventStatus: 'success',
      eventNonInteraction: '0',
      blockName: 'blockFinance',
      fundingProduct: product,
    })
  }

  return { onProductCardClickGtmEvent }
}
