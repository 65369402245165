import { TOrderPaymentVariants } from '@/types/Cart'
import { ISkuDeliveryConditions, TSkuCultures } from '@/types/Catalog'
import { IDeliveryBasesData, IHarvestSaleCulture } from '@/types/HarvestSaleLots'
import { IStoreLocation } from '@/types/IStore'

export enum EFcaCalculateSettings {
  YANDEX = 'YANDEX',
  DATABASE = 'DATABASE',
}

export default interface IOrganization {
  id: string
  name: string
  image?: string
  director?: string
  creationDate?: string
  updateDate?: string
  inn: string
  ogrn?: string
  kpp?: string
  okved?: string
  okpo?: string
  billingAccount?: string
  correspondentAccount?: string
  bank?: string
  bankName?: string
  bik?: string
  phone?: string
  email?: string
  address?: string
  description?: string
  employeesCount?: number
  activityKind?: string
  cropArea?: string
  code?: string
  actualityDate?: string
  registrationDate?: string
  liquidationDate?: string
  orderStatusModelType?: string
  requestStatusModelType?: string
  additionalOkveds?: string[]
  types?: string[]
  deliveryConditions?: ISkuDeliveryConditions
  status?: string
  juridicalStatus?: string
  fcaCalculateSetting?: EFcaCalculateSettings
  deliveryBasesData: IDeliveryBasesData[]
  buyerCultures: IHarvestSaleCulture[]
  cultures?: TSkuCultures
  payment?: TOrderPaymentVariants
  defaultStore?: {
    location: IStoreLocation
  }
}

export type TOrganizations = IOrganization[]
